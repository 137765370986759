import { useCallback, useEffect, useState, useRef } from 'react';
import { Inertia } from '@inertiajs/inertia';
import { throttle } from 'lodash';
import axios from 'axios';
import {
  Box,
  Button,
  Chip,
  TextField,
  Typography,
  Stack,
  useTheme
} from '@mui/material';
import PlayersList from './PlayersList';
import { PLAYER_COLORS } from '../../constants/colors';
import Ereader from './Ereader';

const Actions = ({
  handleFinishStory,
  handleTagPlayer,
  isCurrentPlayer,
  isManager,
  nextColor,
  nextPlayer,
}) => {
  const theme = useTheme();

  return (
    <>
      {isCurrentPlayer && (
        <Box display="flex" alignItems="center" width="100%">
          <Button
            fullWidth
            variant="contained"
            size="small"
            onClick={handleTagPlayer}
            sx={{
              ...(theme.gameButton || {})
            }}
          >
            Tag
            &nbsp;
            <Chip
              size="small"
              label={nextPlayer.name}
              sx={{
                backgroundColor: nextColor,
                border: `1px solid ${nextColor}`,
                color: theme.palette.common.white,
              }}
            />
          </Button>
          {isManager && (
            <>
              <Box component="span" mx={1}>
                <Typography>or</Typography>
              </Box>
              <Button
                fullWidth
                variant="outlined"
                size="small"
                onClick={handleFinishStory}
                sx={{
                  alignSelf: 'stretch',
                  ...(theme.secondaryGameButton || {})
                }}
              >
                Finish the story
              </Button>
            </>
          )}
        </Box>
      )}
    </>
  )
}

const Playing = ({
  player,
  story,
  storyChannel,
  workingCurrentText
}) => {
  const {
    hash_id: storyHashId,
    manager,
    players,
    active_turn: {
      hash_id: activeTurnId,
      text: activeTurnText,
      player: currentPlayer
    },
    turns,
    title,
  } = story;
  const {hash_id: currentPlayerHashId, name: currentPlayerName} = currentPlayer;

  const [currentText, setCurrentText] = useState(workingCurrentText || "");
  const [isSaving, setIsSaving] = useState(false);
  const textAreaRef = useRef();
  const storyContainerRef = useRef();
  const STREAMING_DELAY = 1000; // ms
  const SAVING_DELAY = 5000;
  const streamCurrentText = useCallback(
    throttle((msg, channel) => {
      if(channel) {
        channel.send({text: msg})
      }
    }, STREAMING_DELAY),
    []
  );
  const savePeriodically = useCallback(
    throttle((msg) => {
      setIsSaving(true);
      // TODO: handle an error
      axios.put(`/stories/${storyHashId}/turns/${activeTurnId}`, {
        text: msg
      }, {
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        },
      }).then(() => setIsSaving(false))
    }, SAVING_DELAY),
    [storyHashId, activeTurnId]
  )

  useEffect(() => {
    if (isCurrentPlayer) {
      streamCurrentText(currentText, storyChannel)
    }
  }, [currentText, storyChannel]);

  useEffect(() => {
    savePeriodically(currentText)
  }, [currentText])

  const theme = useTheme();

  const isCurrentPlayer = !!player && player.hash_id === currentPlayerHashId;

  useEffect(() => {
    if (!isCurrentPlayer) {
      setCurrentText(workingCurrentText);
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [workingCurrentText])

  useEffect(() => {
    if (isCurrentPlayer) {
      setCurrentText("")
      textAreaRef.current.focus()
      storyContainerRef.current.scrollTop = storyContainerRef.current.scrollHeight;
    }
  }, [isCurrentPlayer]);

  useEffect(() => {
    storyContainerRef.current.scrollTop = storyContainerRef.current.scrollHeight;
  }, [])


  const handleTagPlayer = async () => {
    await axios.post(
      `/stories/${storyHashId}/turns/${activeTurnId}/submissions`,
      {
        text: currentText
      },
      {
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        },
      }
    );

    // TODO: what happens if submission fails?
    storyContainerRef.current.scrollTop = storyContainerRef.current.scrollHeight;
  };

  const handleFinishStory = () => {
    axios.post(
      `/stories/${storyHashId}/finishes`,
      {
        text: currentText
      },
      {
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        }
      }
    ).then((_) => { /* do nothing */});
  }


  const currentPlayerIndex = players.map((p) => p.hash_id).indexOf(currentPlayerHashId);
  const currentColor = PLAYER_COLORS[currentPlayerIndex];
  const nextPlayer = players[currentPlayerIndex + 1] || players[0];
  const nextColor = PLAYER_COLORS[currentPlayerIndex + 1] || PLAYER_COLORS[0];
  const isManager = !!player && !!manager && player.hash_id === manager.hash_id;

  return <Box mt={4}>
    <Typography variant="h1" align="center" fontSize="2rem" mb={2}>
      {title}
    </Typography>
    <Box justifyContent="center" display="flex">
      <Box>
        <Ereader
          mb={1}
          ref={storyContainerRef}
          story={story}
          bottomCasingContent={(
            <Actions
              handleFinishStory={handleFinishStory}
              handleTagPlayer={handleTagPlayer}
              isCurrentPlayer={isCurrentPlayer}
              isManager={isManager}
              nextPlayer={nextPlayer}
              nextColor={nextColor}
            />
          )}
        >
          <Box display="flex" flexDirection="column" height="100%">
            <Box sx={{
              flexGrow: 1,
              overflowY: 'scroll',
              px: '2px', // hack to allow box shadows to render on story turns
              '&::-webkit-scrollbar': {
                backgroundColor: 'transparent',
                width: '0.75em',
                opacity: '0.5',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(117, 117, 117, 0.5)',
                opacity: '0.5',
                width: '0.3em',
                borderRadius: '0.3em',
              },
            }}>
              <PlayersList
                story={story}
                player={player}
                sx={{
                  position: 'sticky',
                  top: 0,
                }}
              />
              {turns.filter(({text, finished_at}) => text && !! finished_at).map(({text, hash_id: turnId, player: {name: turnPlayerName}}, idx) => {
                const color = PLAYER_COLORS[idx % players.length];
                return (
                  <Typography
                    variant="body2"
                    key={turnId}
                    sx={{
                      mt: 1,
                      mb: 1,
                      color,
                      ...(theme.turn || {})
                    }}
                  >
                    <Chip
                      label={turnPlayerName}
                      size="small"
                      sx={{
                        mr: 1,
                      }}
                    />
                    {text}
                  </Typography>
                )
              })}
            </Box>
            <TextField
              multiline
              rows={4}
              value={currentText}
              onChange={(e) => setCurrentText(e.currentTarget.value)}
              fullWidth
              inputRef={textAreaRef}
              placeholder={isCurrentPlayer ? "Type here" : `Waiting on ${currentPlayer.name} to start writing`}
              disabled={!isCurrentPlayer}
              inputProps={{
                sx: {
                  fontFamily: 'EB Garamond',
                  ...(theme.mainTextField || {})
                }
              }}
            />
          </Box>
        </Ereader>
        <Box mb={3}>
          {isCurrentPlayer && isSaving && (
            <Box>
              <Typography color="grey.600" sx={{position: 'absolute'}}>saving...</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  </Box>
}

export default Playing;
