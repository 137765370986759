import {
  blue,
  red,  
  deepPurple,
  teal,
  pink,
  purple,
  green,
  orange,
  brown,
  cyan,
} from '@mui/material/colors';

export const PLAYER_COLORS = [
  blue[800],
  red[800],  
  deepPurple[800],
  teal[800],
  pink[800],
  purple[800],
  green[800],
  orange[800],
  cyan[800],
  brown[800],
]
