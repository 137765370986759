import { createTheme } from "@mui/material/styles";
import fontFaces from './fontFaces';

const neonCyberpunkColors = {
  red: '#FB0D1B',
  teal: '#2FACC8',
};

const textShadow = {
  offset: '1px',
  blur: '3px'
}
const deckard = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: fontFaces,
    },
  },
  typography: {
    fontFamily: "'Exo 2', sans-serif",
    h1: {
      color: neonCyberpunkColors.red,
      textShadow: `
        ${textShadow.offset} 0 ${textShadow.blur} ${neonCyberpunkColors.red},
        -${textShadow.offset} 0 ${textShadow.blur} ${neonCyberpunkColors.red},
        0 ${textShadow.offset} ${textShadow.blur} ${neonCyberpunkColors.red},
        0 -${textShadow.offset} ${textShadow.blur} ${neonCyberpunkColors.red}
      `,
      fontFamily: 'Neon Desmon',
      fontWeight: '400',
      fontStyle: 'normal',
    },
    h2: {
      fontFamily: "'Exo 2'",
      fontWeight: 600,
    },
    h3: {
      fontFamily: "'Exo 2'",
      fontWeight: 600,
    },
    h4: {
      fontFamily: "'Exo 2'",
      fontWeight: 600,
    },
    h5: {
      fontFamily: "'Exo 2'",
      fontWeight: 600,
    },
    h6: {
      fontFamily: "'Exo 2'",
      fontWeight: 600,
    },
    body1: {
      fontFamily: "'Exo 2'",
      fontWeight: 400,
    },
    body2: {
      fontFamily: "'Exo 2'",
      fontWeight: 400,
      fontSize: '1rem',
    },
    subtitle1: {
      fontFamily: "'Exo 2'",
      fontSize: '1.25rem',
    },
  },
  ereader: {
    backgroundColor: 'transparent',
    boxShadow: '0 0 2px 1px #fff, inset 0 0 2px 1px #fff',
    border: '1px solid #fff',
  },
  ereaderScreen: {
    backgroundColor: 'transparent',
    boxShadow: '0 0 2px 1px #fff, inset 0 0 2px 1px #fff',
    borderRadius: '10px',
    border: '1px solid #fff',
  },
  storySteps: {
    '& .MuiStepConnector-line': {
      boxShadow: '0 0 2px 1px #fff, inset 0 0 2px 1px #fff',
      borderTop: '1px solid #fff',
    },
    '& .MuiStepLabel-labelContainer': {
      '&, & .Mui-completed, & .Mui-active, & .Mui-disabled': {
        color: '#fff',
        fontFamily: 'Neon Desmon',
      },
    },
  },
  homepageStepper: {
    '& .MuiStepLabel-labelContainer': {
      '&, & .Mui-completed, & .Mui-active, & .Mui-disabled': {
        fontFamily: "'Exo 2'",
      },
    },
  },
  playersCard: {
    borderRadius: '4px',
    backgroundColor: '#1E1E1E',
    backgroundImage: 'none',
    boxShadow: `0 0 2px 1px ${neonCyberpunkColors.teal}, inset 0 0 2px 1px ${neonCyberpunkColors.teal}`,
    border: `1px solid ${neonCyberpunkColors.teal}`,
  },
  turn: {
    padding: '8px',
    color: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 0px 3px 0px #fff',
  },
  editTitleCaption: {
    fontFamily: "'Exo 2'",
  },
  mainTextField: {
    fontFamily: "'Exo 2'",
  },
  palette: {
    mode: 'dark',
    primary: {
      main: neonCyberpunkColors.teal,
    },
    paper: {
      main: '#fff',
    }
  }
});

export default deckard;
