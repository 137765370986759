import {
  Box,
  Button,
  Paper,
  Typography,
} from '@mui/material';
import { Inertia } from '@inertiajs/inertia';
import StorySteps from '../components/Story/StorySteps';

export default function MyStories({player, stories}) {
  const handleReadMore = ({hash_id}) => Inertia.visit(`/stories/${hash_id}`);

  return (
    <Box mt={4}>
      <Typography variant="h1" align="center" fontSize="2rem" mb={2}>
        My Stories
      </Typography>
      <Box display="flex" justifyContent="center">
        <Box width="600px">
          {stories.map((story) => {
            const {hash_id, title, turns} = story;
            const firstTurn = (turns || [])[0];
            return (
              <Paper
                key={hash_id}
                elevation={3}
                sx={{
                  p: 2,
                  mb: 2
                }}
              >
                <Typography variant="h5">{title || "Untitled Story"}</Typography>
                <StorySteps story={story} sx={{my: 2}} />
                <Typography variant="body2" sx={{mb: 4}}>{firstTurn && firstTurn.text}</Typography>
                <Button variant="text" onClick={() => handleReadMore(story)}>Read More &#8594;</Button>

              </Paper>
            )
          })}
        </Box>
      </Box>
    </Box>
  );
}
