import { useState, useEffect, useRef } from 'react';
import {
  Box,
} from '@mui/material';
import StoriesChannel from '../channels/StoriesChannel';
import Setup from '../components/Story/Setup';
import Playing from '../components/Story/Playing';
import FinishedStory from '../components/Story/FinishedStory';

export default function Story({story: storyFromServer, player}) {
  const initialText = storyFromServer && storyFromServer.active_turn ? storyFromServer.active_turn.text : ""
  const [story, setStory] = useState(storyFromServer);
  const [workingCurrentText, setWorkingCurrentText] = useState(initialText);
  const [isChannelLoaded, setIsChannelLoaded] = useState(false);
  const storyChannelRef = useRef();

  useEffect(() => {
    const channel = StoriesChannel(story.hash_id);
    channel.received = ({story, working_text}) => {
      const workingText = (working_text === "" || !!working_text) ?
        working_text :
        (story.active_turn ? story.active_turn.text : "")
      setStory(story);
      setWorkingCurrentText(workingText);
    };
    storyChannelRef.current = channel;
    // Force a re-render now that the ref is set
    setIsChannelLoaded(true);
  }, []);

  const {state} = story;

  return <Box>
    {state === 'setting_up' && <Setup story={story} player={player} />}
    {state === 'playing' && <Playing story={story} player={player} storyChannel={storyChannelRef.current} workingCurrentText={workingCurrentText} />}
    {state === 'finished' && <FinishedStory story={story} player={player} />}
  </Box>
}

