import {useState} from 'react';
import {Alert, AlertTitle, Box, Button, List, ListItem, ListItemText, Stepper, Step, StepLabel, StepContent, TextField, Typography, useTheme} from '@mui/material';
import axios from 'axios';
import { Inertia } from '@inertiajs/inertia';
import {usePage} from '@inertiajs/inertia-react';


function LoggedInUserForm({user}) {
  const {email} = user;
  const handleCreateStory = () => {
    Inertia.post('/stories', {
      name: email,
    }, {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      }
    })
  }

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Typography mr={2}>
        Welcome,
        {' '}
        {email}!
      </Typography>

      <Button variant="contained" onClick={handleCreateStory}>
        Create a new story
      </Button>
    </Box>
  )
}

function SignupForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState([]);
  const [isPending, setIsPending] = useState(false);

  const handleSignup = () => {
    setErrors([]);
    setIsPending(true);

    axios.post('/signup', {
      user: {
        email,
        password,
        password_confirmation: passwordConfirmation,
      },
    }, {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      }
    }).then((response) => {
      window.location.reload();
    }).catch((e) => {
      setErrors(e.response.data.errors);
      setIsPending(false);
    });
  };

  const anyFieldsEmpty = !email || !password || !passwordConfirmation;
  const doPasswordsMatch = password === passwordConfirmation;

  const isDisabled = anyFieldsEmpty || isPending || !doPasswordsMatch;

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
      <Typography variant="body2" sx={{mb: 2}}>
        Create an account to keep a record of your stories and to share them with others.
      </Typography>
      {errors.length > 0 && (
        <Box mb={2} sx={{
          width: '100%',
        }}>
          <Alert severity="error">
            <AlertTitle>Please correct the following errors:</AlertTitle>
            <List sx={{ listStyleType: 'disc' }}>
              {errors.map((error) => (
                <ListItem sx={{ display: 'list-item', mb: 0, py:0 }} key={error}>
                  <ListItemText primary={error} />
                </ListItem>
              ))}
            </List>
          </Alert>
        </Box>
      )}
      <TextField
        size="small"
        label="Email address"
        onChange={(e) => setEmail(e.currentTarget.value)}
        value={email}
        sx={{
          mb: 1,
        }}
      />
      <TextField
        size="small"
        label="Password"
        type="password"
        onChange={(e) => setPassword(e.currentTarget.value)}
        value={password}
        sx={{
          mb: 1,
        }}
      />
      <TextField
        size="small"
        label="Confirm Password"
        type="password"
        onChange={(e) => setPasswordConfirmation(e.currentTarget.value)}
        value={passwordConfirmation}
        sx={{
          mb: 2,
        }}
      />
      <Button variant="contained" onClick={handleSignup} disabled={isDisabled}>
        Sign Up
      </Button>
    </Box>
  )
}

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isPending, setIsPending] = useState(false);

  const handleLogin = () => {
    setError('');
    setIsPending(true);

    axios.post('/login', {
      user: {
        email,
        password,
      },
    }, {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      }
    }).then((response) => {
      window.location.reload();
    }).catch((e) => {
      setError(e.response.data.error);
      setIsPending(false);
    });
  };

  return (
    <Box>
      <Typography variant="body2" sx={{mb: 1}}>
        Log in to your existing account.
      </Typography>
      {error && (
        <Box mb={2} sx={{
          width: '100%',
        }}>
          <Alert severity="error">
            <AlertTitle>Please correct the following errors:</AlertTitle>
            <List sx={{ listStyleType: 'disc' }}>
              <ListItem sx={{ display: 'list-item', mb: 0, py:0 }}>
                <ListItemText primary={error} />
              </ListItem>
            </List>
          </Alert>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <TextField
          size="small"
          label="Email address"
          onChange={(e) => setEmail(e.currentTarget.value)}
          value={email}
          sx={{
            mr: 1,
          }}
        />
        <TextField
          size="small"
          label="Password"
          type="password"
          onChange={(e) => setPassword(e.currentTarget.value)}
          value={password}
          sx={{
            mr: 2,
          }}
        />
        <Button variant="contained" onClick={handleLogin}>
          Login
        </Button>
      </Box>
    </Box>
  );
}

function LoginStepper({player}) {
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');

  const {name: playerName} = player || {};

  const theme = useTheme();

  const handleCreateStory = () => {
    Inertia.post('/stories', {
      name,
    }, {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      }
    })
  }

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      nonLinear
      sx={{
        width: 500,
        '& .MuiStepLabel-labelContainer': {
          '&, & .Mui-completed, & .Mui-active, & .Mui-disabled, & .MuiStepLabel-label': {
            fontFamily: 'Oswald',
          },
        },
        '& .MuiStepConnector-line': {
          minHeight: '10px',
        },
        ...(theme.homepageStepper || {})
      }}
    >
      <Step onClick={() => setActiveStep(0)}>
        <StepLabel
          sx={{
            '&, &.Mui-disabled': {
              cursor: 'pointer',
            }
          }}
        >
          Sign Up
        </StepLabel>
        <StepContent TransitionProps={{ unmountOnExit: false }}>
          <SignupForm />
        </StepContent>
      </Step>
      <Step onClick={() => setActiveStep(1)}>
        <StepLabel
          sx={{
            '&, &.Mui-disabled': {
              cursor: 'pointer',
            }
          }}
        >
          Log In
        </StepLabel>
        <StepContent TransitionProps={{ unmountOnExit: false }}>
          <LoginForm />
        </StepContent>
      </Step>
      <Step onClick={() => setActiveStep(2)}>
        <StepLabel
          sx={{
            '&, &.Mui-disabled': {
              cursor: 'pointer',
            }
          }}
        >
          Play without an account
        </StepLabel>
        <StepContent TransitionProps={{ unmountOnExit: false }}>
          <Typography variant="body2" sx={{mb: 1}}>
            No sign up needed! Just enter your name and start playing.
          </Typography>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            {!!player && (
              <Typography mr={2}>
                Welcome back,
                {' '}
                {playerName}!
              </Typography>
            )}
            {!player && (
              <TextField
                label="Choose a name"
                size="small"
                onChange={(e) => setName(e.currentTarget.value)}
                value={name}
                disabled={!!player}
                onKeyUp={(e) => {
                  if(e.keyCode === 13) {
                    handleCreateStory();
                  }
                }}
                sx={{
                  mr: 2,
                }}
              />
            )}

            <Button variant="contained" onClick={handleCreateStory} disabled={!player && !name}>
              Create a new story
            </Button>
          </Box>
        </StepContent>
      </Step>
    </Stepper>
  );
}

export default function CreateStory({player}) {
  const { props: {current_user: currentUser} } = usePage();
  return (
    <>
      {!!currentUser && (
        <LoggedInUserForm user={currentUser} />
      )}
      {!currentUser && (
        <LoginStepper player={player} />
      )}
    </>
  );
}
