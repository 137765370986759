import OswaldRegular from "~/assets/fonts/Oswald-Regular.ttf";
import OswaldSemiBold from "~/assets/fonts/Oswald-SemiBold.ttf";
import OswaldBold from "~/assets/fonts/Oswald-Bold.ttf";
import EBGaramondRegular from "~/assets/fonts/EBGaramond-Regular.ttf";
import AudiowideRegular from "~/assets/fonts/Audiowide-Regular.ttf";
import MuseoModernLight from "~/assets/fonts/MuseoModerno-Light.ttf";
import NeonDesmon from "~/assets/fonts/NeonDesmon.otf";
import Exo2Regular from "~/assets/fonts/Exo2-Regular.ttf";
import Exo2Medium from "~/assets/fonts/Exo2-Medium.ttf";
import Exo2SemiBold from "~/assets/fonts/Exo2-SemiBold.ttf";

const fontFaces = `
  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Oswald'), url(${OswaldRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: bold;
    src: local('Oswald'), url(${OswaldBold}) format('truetype');
  }
  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local('Oswald'), url(${OswaldSemiBold}) format('truetype');
  }
  @font-face {
    font-family: 'EB Garamond';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('EB Garamond'), url(${EBGaramondRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Audiowide';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(${AudiowideRegular}) format("truetype");
  }
  @font-face {
    font-family: 'MuseoModerno';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('MuseoModerno'), url(${MuseoModernLight}) format('truetype');
  }
  @font-face {
    font-family: 'Neon Desmon';
    font-style: normal;
    font-display: swap;
    font-weight: normal;
    src: local("Neon Desmon"), url(${NeonDesmon}) format("opentype");
  }
  @font-face {
    font-family: 'Exo 2';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local("Exo 2"), url(${Exo2Regular}) format("truetype");
  }
  @font-face {
    font-family: 'Exo 2';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local("Exo 2"), url(${Exo2Medium}) format("truetype");
  }
  @font-face {
    font-family: 'Exo 2';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local("Exo 2"), url(${Exo2SemiBold}) format("truetype");
  }
`

export default fontFaces;
