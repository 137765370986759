import deckard from './deckard';
import sunCity from './sunCity';
import spree from './spree';
//import slateForest from './slateForest';

const themes = [
  {
    name: 'Spree',
    data: spree,
  },
  {
    name: 'Sun City',
    data: sunCity,
  },
  {
    name: 'Deckard',
    data: deckard,
  },
  //{
    //name: 'Slate Forest',
    //data: slateForest,
  //},
]

export default themes;
