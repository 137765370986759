import React from 'react'
import { render } from 'react-dom'
import { createInertiaApp } from '@inertiajs/inertia-react'
import { InertiaProgress } from '@inertiajs/progress'
import * as Pages from '../Pages/';
import DefaultLayout from '../Pages/Layouts/Default';
import Base from '../components/Base';

document.addEventListener('DOMContentLoaded', () => {
  const app = document.getElementById('app');

  if (app) {
    createInertiaApp({
      resolve: (name) => {
        let Page = Pages[name];
        Page.layout = Page.layout === undefined ? DefaultLayout : Page.layout;
        return Page;
      },
      setup({ el, App, props }) {
        render(
          <Base>
            <App initialPage={JSON.parse(app.dataset.page)} {...props} />
          </Base>,
          el,
        );
      },
    });

  }
});

InertiaProgress.init()
