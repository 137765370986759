import {
  Step,
  StepLabel,
  Stepper,
  useTheme,
} from '@mui/material';

const steps = [
  {
    key: 'setting_up',
    label: 'Setup',
  },
  {
    key: 'playing',
    label: 'Playing',
  },
  {
    key: 'finished',
    label: 'Finished',
  },
];

export default function StorySteps({story: { state }, sx = {}, color}) {
  const activeStep = steps.map(s => s.key).indexOf(state);
  const theme = useTheme();

  return (
    <Stepper
      activeStep={activeStep}
      sx={{
        '& .MuiStepLabel-iconContainer': {
          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
          },
        },
        '& .MuiStepLabel-labelContainer': {
          '&, & .Mui-completed, & .Mui-active, & .Mui-disabled': {
            color: color || theme.palette.text.primary,
            fontFamily: 'Oswald',
          },
        },
        ...(theme.storySteps || {}),
        ...sx
      }}
    >
      {steps.map(({key, label}, idx) => (
        <Step key={key} completed={activeStep > idx || (activeStep === idx && idx === steps.length - 1)}>
          <StepLabel sx={{ color: '#fff' }}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
