import { createTheme } from "@mui/material/styles";
import fontFaces from './fontFaces';
import oswaldAndGaramond from './oswaldAndGaramond';

const purpleAndOrangeColors = {
  purple: '#615496',
  orange: '#F05934',
  green: '#8DCBA9',
  tan: '#f5f4ef',
}
const sunCity = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: fontFaces,
    },
  },
  ...oswaldAndGaramond,
  palette: {
    primary: {
      main: purpleAndOrangeColors.purple,
    },
    paper: {
      main: purpleAndOrangeColors.tan,
    }
  },
  homepageStepper: {
    '& .MuiStepLabel-iconContainer': {
      '& .MuiSvgIcon-root': {
        border: '2px solid #000',
        boxShadow: 'inset 0 0 0 1px #000',
        borderRadius: '50%',
      },
      '& .Mui-completed': {
        color: purpleAndOrangeColors.green,
        border: '2px solid #000',
        boxShadow: 'inset 0 0 0 1px #000',
        borderRadius: '50%',
      },
      '& .Mui-active': {
        color: purpleAndOrangeColors.orange,
        border: '2px solid #000',
        boxShadow: 'inset 0 0 0 1px #000',
        borderRadius: '50%',
      },
    },
  },
  storySteps: {
    '& .MuiStepLabel-iconContainer': {
      '& .MuiSvgIcon-root': {
        border: '2px solid #000',
        boxShadow: 'inset 0 0 0 1px #000',
        borderRadius: '50%',
      },
      '& .Mui-completed': {
        color: purpleAndOrangeColors.green,
        border: '2px solid #000',
        boxShadow: 'inset 0 0 0 1px #000',
        borderRadius: '50%',
      },
      '& .Mui-active': {
        color: purpleAndOrangeColors.orange,
        border: '2px solid #000',
        boxShadow: 'inset 0 0 0 1px #000',
        borderRadius: '50%',
      },
    },
    '& .MuiStepConnector-line': {
      borderTopWidth: '3px',
      borderColor: purpleAndOrangeColors.purple,
    },
  },
  secondaryGameButton: {
    color: '#fff',
    border: `1px solid ${purpleAndOrangeColors.orange}`
  },
  bottomCasingContent: {
    color: '#fff',
  },
});

export default sunCity;
