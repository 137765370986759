import consumer from './consumer';

const StoriesChannel = (storyId) => {
  return consumer.subscriptions.create({
    channel: 'StoriesChannel',
    id: storyId
  }, {})
};

export default StoriesChannel;
