import { Inertia } from '@inertiajs/inertia';
import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Step,
  Stepper,
  StepContent,
  StepLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import {usePage} from '@inertiajs/inertia-react';
import CreateStory from '../components/CreateStory';

const explanationSteps = [
  {
    label: 'Create the game',
    description: <>
      <Typography variant="body2" mb={1}>
        Here on the homepage, choose a username and create a game
      </Typography>
      <Typography variant="body2">
        If you have already played before, your username will be remembered.
      </Typography>
    </>,
  },
  {
    label: 'Setup the game & invite others to join',
    description: <>
      <Typography variant="body2" mb={1}>
      The game starts in setup mode. You can edit the title of the game while waiting for others to join.
      </Typography>
      <Typography variant="body2">

        Copy-paste the link on the page and send it to others. They will have the opportunity to choose their own username and join the game.
      </Typography>
    </>
  },
  {
    label: 'Start the game',
    description: <>
      <Typography variant="body2" mb={1}>
        Once at least 2 players (and up to 10 players) have joined, click to start the game.
      </Typography>
    </>
  },
  {
    label: 'Write the story and tag the nexy player',
    description: <>
      <Typography variant="body2" mb={1}>
        The game progresses in turns. The creator of the game starts the story, writing as much as they want.
      </Typography>
      <Typography variant="body2" mb={1}>
        Once finished, they tag the next player.
      </Typography>
      <Typography variant="body2" mb={1}>
        Each player writes their own piece of the story, tagging to advance the turn.
      </Typography>
    </>
  },
  {
    label: 'Finish the story',
    description: <>
      <Typography variant="body2" mb={1}>
        The game creator can choose to finish the game during any of their turns.
      </Typography>
      <Typography variant="body2">
        The story will remain visible to read for anyone with the link.
      </Typography>
    </>
  },
]

function Explanation({...rest}) {
  const [activeStep, setActiveStep] = useState(0);
  const [isAutomatic, setIsAutomatic] = useState(true);
  const timer = useRef();
  const theme = useTheme();

  useEffect(() => {
    if (isAutomatic) {
      timer.current = setTimeout(() => {
        setActiveStep(current => {
          return current === explanationSteps.length - 1 ? 0 : current + 1;
        })
      }, 7000);
      return () => clearTimeout(timer.current);
    }
  }, [activeStep, isAutomatic]);

  const handleClickStep = (stepNumber) => {
    if (timer.currrent) {
      clearTimeout(timer.currrent)
    }
    setIsAutomatic(false);
    setActiveStep(stepNumber);
  }

  return <Box justifyContent="center" display="flex" {...rest}>
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      sx={{
        width: 500,
        '& .MuiStepLabel-labelContainer': {
          '&, & .Mui-completed, & .Mui-active, & .Mui-disabled': {
            fontFamily: 'Oswald',
          },
        },
        '& .MuiStepConnector-line': {
          minHeight: '10px',
        },
        ...(theme.homepageStepper || {})
      }}
    >
      {explanationSteps.map((step, index) => (
        <Step key={step.label} onClick={() => handleClickStep(index)}>
          <StepLabel
            sx={{
              '&, &.Mui-disabled': {
                cursor: 'pointer',
              }
            }}
          >
            {step.label}
          </StepLabel>
          <StepContent>{step.description}</StepContent>
        </Step>
      ))}
    </Stepper>
  </Box>
}

export default function Home({ player }) {
  const { props: { current_user: currentUser } } = usePage();
  return <Box p={4}>
    <Typography variant="h1" align="center" textTransform="uppercase">Story Tag</Typography>
    <Typography align="center" variant="subtitle1" mb={3}>
      Story tag is a game in which you collaboratively tell a story with a group of friends or strangers.
    </Typography>
    <Typography align="center" textTransform="uppercase">
      Here&apos;s how it works:
    </Typography>
    <Box sx={{ height: '360px' }}>
      <Explanation mb={8} />
    </Box>

    <Typography variant="h2" align="center" mb={2}>Ready to play?</Typography>
    {!currentUser && (
      <Typography align="center" textTransform="uppercase">
        Choose an option:
      </Typography>
    )}
    <Box justifyContent="center" display="flex">
      <CreateStory player={player} />
    </Box>
  </Box>
}
