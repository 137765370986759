import { useContext, useRef, useState } from 'react';
import { Inertia } from '@inertiajs/inertia';
import { usePage } from '@inertiajs/inertia-react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Slide,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material';
import PaletteIcon from '@mui/icons-material/Palette';
import { SetThemeContext } from '../../components/Base';
import themes from '../../config/theme';
import axios from 'axios';

function Layout({children}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isThemeMenuOpen, setIsThemeMenuOpen] = useState(false);
  const anchorEl = useRef();
  const themeAnchorEl = useRef();
  const {
    canSeeThemes,
    currentThemeName,
    hasActivatedThemes,
    setTheme,
    setHasActivatedThemes,
  } = useContext(SetThemeContext);

  const theme = useTheme();
  const { props: { player, current_user: currentUser } } = usePage();

  const handleClose = () => setIsMenuOpen(false);
  const handleThemeClose = () => setIsThemeMenuOpen(false);

  const handleLogout = () => {
    axios.delete('/logout', {
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      }
    }).then(() => {
      window.location.reload();
    }).catch(() => {
      Inertia.reload();
    });
  };

  return (
    <Box mt={2}>
      <Box position="absolute" right={theme.spacing(2)} top={theme.spacing(2)} display="flex">

        {canSeeThemes && (
          <>
            <Box>
              <Button
                onClick={() => setIsThemeMenuOpen(true)}
                ref={themeAnchorEl}
              >
                <PaletteIcon />
              </Button>
              <Menu
                anchorEl={themeAnchorEl.current}
                open={isThemeMenuOpen}
                onClose={handleThemeClose}
              >
                {themes.map(({name}) => {
                  return (
                    <MenuItem key={name} onClick={() => setTheme(name)}>
                      {name}
                      {currentThemeName === name && (
                        ` (Current)`
                      )}
                    </MenuItem>
                  )
                })}
              </Menu>
            </Box>
            <Snackbar
              open={!hasActivatedThemes}
              autoHideDuration={4000}
              onClose={() => setHasActivatedThemes(true)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              message={
                <Typography>Themes unlocked!</Typography>
              }
              TransitionComponent={Slide}
              TransitionProps={{
                direction: 'left',
              }}
            />
          </>
        )}
        <Box>
          <Button
            onClick={() => setIsMenuOpen(true)}
            ref={anchorEl}
          >
            {!currentUser && 'Menu'}
            {!!currentUser && `Menu (${currentUser.email})`}
          </Button>
          <Menu
            anchorEl={anchorEl.current}
            open={isMenuOpen}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {
              handleClose();
              Inertia.visit("/");
            }}>
              Home
            </MenuItem>
            {!!player && (
              <MenuItem onClick={() => {
                handleClose();
                Inertia.visit("/my_stories");
              }}>
                My Stories
              </MenuItem>
            )}
            {!!currentUser && (
              <MenuItem onClick={handleLogout}>
                Logout
              </MenuItem>
            )}
          </Menu>

        </Box>
      </Box>
      {children}
    </Box>
  )
}

export default (page) => <Layout>{page}</Layout>
