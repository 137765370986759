import { useLayoutEffect } from 'react';
import {
  Box,
  Chip,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { PLAYER_COLORS } from '../../constants/colors';

const chipId = ({hash_id}) => `player-chip-${hash_id}`;

export default function PlayersList({
  children,
  story,
  player = {},
  sx = {}
}) {
  const {
    players,
    active_turn,
    configuration: {
      max_players,
    },
  } = story;

  const { player: { hash_id: activePlayerHashId } } = active_turn || { player: {} };

  const theme = useTheme();

  // https://stackoverflow.com/questions/65171427/react-useref-scrolling-just-working-within-settimeout
  // Scrolling is wonky on initial payload unless this is wrapped in a timeout.
  // Can't find the root cause but my guess is that the browser runs some layout stuff async
  // and if it's not finished then scrolling the div doesn't always work properly.
  useLayoutEffect(() => {
    setTimeout(() => {
      document.querySelector(`#${chipId({hash_id: activePlayerHashId})}`)
        .scrollIntoView({ behavior: 'smooth' })
    }, 350)
  }, [activePlayerHashId])

  return <Paper
    elevation={3}
    sx={{
      p:1,
      ...(theme.playersCard || {}),
      ...sx
    }}
  >
    <Box display="flex">
      <Typography variant="overline" sx={{fontWeight: 'bold'}}>
        {players.length > 1 ? `${players.length} Players` : '1 Player'}
      </Typography>
    </Box>
    <Stack
      direction="row"
      alignItems="flex-start"
      spacing={1}
      sx={{
        pr: 1,
        overflowX: 'auto',
      }}
    >
      {players.map(({hash_id, name}, idx) => {
        const isCurrentPlayer = !!player && hash_id === player.hash_id;
        const hasActiveTurn = hash_id === activePlayerHashId || (!active_turn && idx === 0);
        return <Box component="span" display="flex" flexDirection="column" alignItems="center" key={hash_id}>
          <Chip
            id={chipId({hash_id})}
            label={name}
            key={name}
            size="small"
            sx={{
              backgroundColor: hasActiveTurn ? PLAYER_COLORS[idx] : theme.palette.common.white,
              color: hasActiveTurn ? theme.palette.common.white : PLAYER_COLORS[idx],
              border: `1px solid ${PLAYER_COLORS[idx]}`,
            }}
          />
          {isCurrentPlayer && (
            <Box component="span">
              <Typography variant="overline" sx={{ lineHeight: '0.5' }}>You!</Typography>
            </Box>
          )}
        </Box>
      })}
    </Stack>
    {children}
  </Paper>
}
