import { createTheme } from "@mui/material/styles";
import fontFaces from './fontFaces';
import oswaldAndGaramond from './oswaldAndGaramond';

const blueAndOrangeColors = {
  blue: '#226ADB',
  orange: '#F54F2D',
  yellow: '#F5A945',
  pink: '#F2BECD',
  tan: '#FCF7EE',
};

const spree = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: fontFaces,
    },
  },
  ...oswaldAndGaramond,
  palette: {
    primary: {
      main: blueAndOrangeColors.blue,
    },
    paper: {
      main: blueAndOrangeColors.tan,
    }
  },
  homepageStepper: {
    '& .MuiStepLabel-iconContainer': {
      '& .MuiSvgIcon-root': {
        borderRadius: '50%',
      },
      '& .Mui-completed': {
        color: blueAndOrangeColors.orange,
        borderRadius: '50%',
        boxShadow: `0px 0px 4px 1px ${blueAndOrangeColors.yellow}`
      },
      '& .Mui-active': {
        color: blueAndOrangeColors.blue,
        borderRadius: '50%',
        boxShadow: `0px 0px 4px 1px ${blueAndOrangeColors.yellow}`
      },
    },
  },
  storySteps: {
    '& .MuiStepLabel-iconContainer': {
      '& .MuiSvgIcon-root': {
        borderRadius: '50%',
      },
      '& .Mui-completed': {
        color: blueAndOrangeColors.orange,
        borderRadius: '50%',
      },
      '& .Mui-active': {
        color: blueAndOrangeColors.blue,
        borderRadius: '50%',
      },
    },
    '& .MuiStepConnector-line': {
      borderTopWidth: '2px',
      borderColor: blueAndOrangeColors.pink,
    },
  },
  playersCard: {
    boxShadow: `
      0px 3px 3px -2px rgb(245 169 69 / 20%),
      0px 3px 4px 0px rgb(245 169 69 / 14%),
      0px 1px 8px 0px rgb(245 169 69 / 12%)
    `,
  },
  secondaryGameButton: {
    color: '#fff',
    border: `1px solid #fff`
  },
  bottomCasingContent: {
    color: '#fff',
  },
});

export default spree;
