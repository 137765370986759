import { grey } from "@mui/material/colors";

const oswaldAndGaramond = {
  typography: {
    fontFamily: 'Oswald, sans-serif',
    h1: {
      fontFamily: 'Oswald',
      fontWeight: 600,
    },
    h2: {
      fontFamily: 'Oswald',
      fontWeight: 600,
    },
    h3: {
      fontFamily: 'Oswald',
      fontWeight: 600,
    },
    h4: {
      fontFamily: 'Oswald',
      fontWeight: 600,
    },
    h5: {
      fontFamily: 'Oswald',
      fontWeight: 600,
    },
    h6: {
      fontFamily: 'Oswald',
      fontWeight: 600,
    },
    body1: {
      fontFamily: 'Oswald',
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'EB Garamond',
      fontWeight: 400,
      fontSize: '1rem',
    },
    subtitle1: {
      fontFamily: 'EB Garamond',
      fontSize: '1.25rem',
      color: grey[600],
    },
  },
};

export default oswaldAndGaramond;
