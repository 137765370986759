import {createContext, useEffect, useState} from 'react';
import { ThemeProvider, CssBaseline } from "@mui/material";
import themes from '../config/theme';

export const SetThemeContext = createContext();

export default function Base({children}){
  const [theme, setTheme] = useState(themes[0]);
  const [canSeeThemes, setCanSeeThemes] = useState(false);
  const [isLeaderPressed, setIsLeaderPressed] = useState(false);
  const [hasActivatedThemes, setHasActivatedThemes] = useState(false);

  const handleKeyDown = (e) => {
    if (e.keyCode === 220) {
      setIsLeaderPressed(true);
    }

    if (isLeaderPressed && e.keyCode === 84) {
      setCanSeeThemes(true);
    }
  };

  const handleKeyUp = (e) => {
    setIsLeaderPressed(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown]);

  return <SetThemeContext.Provider value={{
    currentThemeName: theme.name,
    canSeeThemes,
    hasActivatedThemes,
    setCanSeeThemes,
    setHasActivatedThemes,
    setTheme: (newName) => {
      const newTheme = themes.find(({name}) => name === newName) || themes[0];
      setTheme(newTheme);
    },
  }}>
    <ThemeProvider theme={theme.data}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </SetThemeContext.Provider>
}
