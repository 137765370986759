import { useEffect, useState, useRef } from 'react';
import { Inertia } from '@inertiajs/inertia';
import axios from 'axios';
import {
  Box,
  Button,
  Slide,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import PlayersList from './PlayersList';
import Ereader from './Ereader';

function Title({isManager, story: {title, hash_id}}) {
  const [storyTitle, setStoryTitle] = useState(title);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isSavingTitle, setIsSavingTitle] = useState(false);
  const titleFieldRef = useRef();
  const theme = useTheme();

  const handleClickEditTitle = () => {
    setIsEditingTitle(true);
  }

  const handleCancelEditTitle = () => {
    setIsEditingTitle(false);
    setStoryTitle(title)
  }

  const handleSaveTitle = async () => {
    setIsSavingTitle(true)
    const {data: {title: updatedTitle}} = await axios.put(`/stories/${hash_id}`, {
      story: {
        title: storyTitle
      },
    },
      {
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        },
      });

    setIsSavingTitle(false);
    setIsEditingTitle(false);
    setStoryTitle(updatedTitle);
  }

  useEffect(() => {
    if (isEditingTitle) {
      titleFieldRef.current.focus();
    }
  }, [isEditingTitle]);

  return (
    <>
      {!isManager && (
        <Typography variant="h1" align="center" fontSize="2rem" mb={2} mr={1}>
          {title}
        </Typography>
      )}
      {isManager && (
        <Box display="flex" justifyContent="center" alignItems="baseline">
          {isEditingTitle && (
            <Box display="flex" alignItems="center">
              <TextField
                value={storyTitle}
                onChange={(e) => setStoryTitle(e.currentTarget.value)}
                disabled={isSavingTitle}
                variant="standard"
                sx={{
                  mb: 2,
                  mr: 1,
                }}
                inputRef={titleFieldRef}
                inputProps={{
                  sx: {
                    fontSize: '2rem',
                    fontWeight: 600,
                  },
                }}
              />
              <Button variant="contained" onClick={handleSaveTitle} disabled={isSavingTitle}>
                {isSavingTitle ? "Saving..." : "Save"}
              </Button>
              <Button variant="text" onClick={handleCancelEditTitle} disabled={isSavingTitle}>cancel</Button>
            </Box>
          )}
          {!isEditingTitle && (
            <>
              <Typography
                variant="h1"
                sx={{
                  mb: 2,
                  align: "center",
                  fontSize: "2rem",
                  mr: 1,
                  cursor: "pointer"
                }}
                onClick={handleClickEditTitle}
              >
                {storyTitle}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  fontFamily: "EB Garamond",
                  cursor: 'pointer',
                  ...(theme.editTitleCaption || {})
                }}
                onClick={handleClickEditTitle}
              >
                (click to edit)
              </Typography>
            </>
          )}
        </Box>
      )}
    </>
  );
}

const ellipsis = keyframes`
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
`;

const BlinkingEllipsis = styled(Typography)(({theme}) => ({
  '&:after': {
    display: 'inline-block',
    animation: `${ellipsis} steps(4,end) 2000ms infinite`,
    content: '""',
  }
}));

function Setup({story, player}) {
  const [name, setName] = useState();
  const [isCopyConfirmedVisible, setIsCopyConfirmedVisible] = useState(false);

  const { name: playerName } = player || {};
  const { manager, players, is_full, configuration: { max_players } } = story || {};

  const theme = useTheme();

  const handleJoinStory = () => {
    Inertia.post(`/stories/${story.hash_id}/players`, {
      name,
    }, {
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      },
      preserveState: true,
      preserveScroll: true,
    })
  }

  const handleStartStory = () => {
    Inertia.post(`/stories/${story.hash_id}/starts`,
      {},
      {
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        },
        preserveState: true,
      }
    )
  }

  const isManager = !!player && !!manager && player.hash_id === manager.hash_id;
  const hasJoined = !!player && story.players.map(p => p.hash_id).includes(player.hash_id);

  return <Box mt={4}>
    <Title isManager={isManager} story={story} />
    <Box justifyContent="center" display="flex">
      <Box>
        <Ereader
          mb={4}
          story={story}
          mx="auto"
          bottomCasingContent={<>
            {isManager && (
              <Button
                variant="contained"
                onClick={handleStartStory}
                fullWidth
                size="small"
                sx={{ ...(theme.gameButton || {}) }}
              >
                Start the game
              </Button>
            )}
          </>}
        >
          <PlayersList story={story} player={player}>
            <Typography
              variant="overline"
              sx={{
                fontWeight: 'bold',
                display: 'block',
              }}
            >
              {`Max players: ${max_players}`}
            </Typography>
            {!hasJoined && is_full && (
              <Typography>The game is full!</Typography>
            )}
            {!player && !is_full && (
              <Box mt={2}>
                <Box mb={2}>
                  <TextField
                    label="Your username"
                    size="small"
                    onChange={(e) => setName(e.currentTarget.value)}
                    value={name}
                    onKeyUp={(e) => {
                      if(e.keyCode === 13) {
                        handleJoinStory();
                      }
                    }}
                  />
                </Box>

                <Button variant="contained" onClick={handleJoinStory}>
                  Join the story
                </Button>
              </Box>
            )}
            {!!player && !hasJoined && !is_full && (
              <Box mt={2}>
                <Typography>
                  Welcome back,
                  {' '}
                  {player.name}!
                </Typography>
                <Button variant="contained" onClick={handleJoinStory}>
                  Join the story
                </Button>
              </Box>
            )}
            {hasJoined && <BlinkingEllipsis>Waiting for game to start</BlinkingEllipsis>}
            {hasJoined && !is_full && (
              <>
                <Typography variant="overline" fontWeight="600" display="flex" alignItems="center">
                  <Box mr={1} display="inline-block" component="span">
                    Invite others!
                    {' '}
                    <Typography variant="overline" fontWeight="400">
                      {window.location.href}
                    </Typography>
                  </Box>
                  <Button
                    display="inline-block"
                    size="small"
                    variant="contained"
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                      setIsCopyConfirmedVisible(true);
                    }}
                  >
                    copy
                  </Button>
                </Typography>
                <Snackbar
                  open={isCopyConfirmedVisible}
                  autoHideDuration={4000}
                  onClose={() => setIsCopyConfirmedVisible(false)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  message={
                    <Typography>Link copied to clipboard</Typography>
                  }
                  TransitionComponent={Slide}
                  TransitionProps={{
                    direction: 'left',
                  }}
                />
              </>
            )}
          </PlayersList>
        </Ereader>
      </Box>
    </Box>
  </Box>
}

export default Setup;
