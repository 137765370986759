import { forwardRef } from 'react';
import {
  Box,
  Button,
  Paper,
  useTheme,
} from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import { styled } from "@mui/material/styles";
import StorySteps from './StorySteps';

const StyledPaper = styled(({height, ...rest}) => <Paper {...rest} />, {
  shouldForwardProp: (prop) => ['height', 'theme', 'children'].includes(prop)
})((props) => {
  const {theme, height} = props;
  return {
    backgroundColor: blueGrey[900],
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    borderRadius: theme.shape.borderRadius * 8,
    position: 'relative',
    height:  `${height || "calc(100vh - 110px)"}`,

    [theme.breakpoints.up("md")]: {
      aspectRatio: "2 / 2.25",
      minWidth: "500px",
      maxWidth: "750px",
      minHeight: "650px",
      maxHeight: "900px",
    },

    [theme.breakpoints.down("md")]: {
      width: '95vw',
      minHeight: "500px",
    },
    ...(theme.ereader || {})
  }
});

function Casing({bottomCasingContent, children, height, story}) {
  const theme = useTheme();

  return <StyledPaper
    elevation={6}
    height={height}
  >
    <StorySteps
      story={story}
      sx={{
        position: 'absolute',
        top: '18px',
        width: 'calc(100% - 64px)',
      }}
      color="#fff"
    />
    {children}
    <Box sx={{
      height: theme.spacing(7),
      display: 'flex',
      alignItems: 'center',
      ...(theme.bottomCasingContent || {}),
    }}>
      {bottomCasingContent}
    </Box>
  </StyledPaper>
}

const Ereader = forwardRef(({children, story, height, bottomCasingContent, ...rest}, ref) => {
  const theme = useTheme();

  return <Box {...rest}>
    <Casing story={story} height={height} bottomCasingContent={bottomCasingContent}>
      <Box
        ref={ref}
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: theme.palette.paper.main,
          textAlign: "left",
          p: 2,
          ...(theme.ereaderScreen || {})
        }}
      >
        {children}
      </Box>
    </Casing>
  </Box>
});

export default Ereader;
