import { useEffect, useRef } from 'react';
import {
  Box,
  Chip,
  Typography,
  useTheme,
} from '@mui/material';
import PlayersList from './PlayersList';
import Ereader from './Ereader';
import { PLAYER_COLORS } from '../../constants/colors';

function FinishedStory({ story, player }) {
  const {players, turns, title} = story;
  const storyContainerRef = useRef();
  const theme = useTheme();

  useEffect(() => {
    storyContainerRef.current.scrollTop = storyContainerRef.current.scrollHeight;
  }, []);

  return (
    <Box mt={4}>
      <Typography variant="h1" align="center" fontSize="2rem" mb={2}>
        {title}
      </Typography>
      <Box justifyContent="center" display="flex">
        <Box>

          <Ereader
            mb={4}
            story={story}
            ref={storyContainerRef}
          >
            <Box
              sx={{
                height: '100%',
                px: '2px', // hack to allow box shadows to render on story turns
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  backgroundColor: 'transparent',
                  width: '0.75em',
                  opacity: '0.5',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(117, 117, 117, 0.5)',
                  opacity: '0.5',
                  width: '0.3em',
                  borderRadius: '0.3em',
                },
              }}
            >
              <PlayersList
                story={story}
                player={player}
                sx={{
                  position: 'sticky',
                  top: 0,
                }}
              />
              {turns.filter(({text, finished_at}) => text && !! finished_at).map(({text, hash_id: turnId, player: { name: turnPlayerName }}, idx) => {
                const color = PLAYER_COLORS[idx % players.length];
                return (
                  <Typography
                    variant="body2"
                    key={turnId}
                    sx={{
                      mt: 1,
                      mb: 1,
                      color,
                      ...(theme.turn || {})
                    }}
                  >
                    <Chip
                      label={turnPlayerName}
                      size="small"
                      sx={{
                        mr: 1,
                      }}
                    />
                    {text}
                  </Typography>
                )
              })}
            </Box>
          </Ereader>
        </Box>
      </Box>
    </Box>
  )
}

export default FinishedStory;
